@font-face {
  font-family: "Pluto";
  src: url("../assets/fonts/Pluto/PlutoBold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Pluto/PlutoBold.otf") format("opentype"),
    url("../assets/fonts/Pluto/PlutoBold.woff") format("woff"),
    url("../assets/fonts/Pluto/PlutoBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Pluto";
  src: url("../assets/fonts/Pluto/PlutoMedium.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Pluto/PlutoMedium.otf") format("opentype"),
    url("../assets/fonts/Pluto/PlutoMedium.woff") format("woff"),
    url("../assets/fonts/Pluto/PlutoMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Pluto";
  src: url("../assets/fonts/Pluto/PlutoRegular.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Pluto/PlutoRegular.otf") format("opentype"),
    url("../assets/fonts/Pluto/PlutoRegular.woff") format("woff"),
    url("../assets/fonts/Pluto/PlutoRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Pluto";
  src: url("../assets/fonts/Pluto/PlutoLight.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Pluto/PlutoLight.otf") format("opentype"),
    url("../assets/fonts/Pluto/PlutoLight.woff") format("woff"),
    url("../assets/fonts/Pluto/PlutoLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Campton';
  src: url('../assets/fonts/Campton/Campton-Bold.woff2') format('woff2'),
    url('../assets/fonts/Campton/Campton-Bold.woff') format('woff'),
    url('../assets/fonts/Campton/Campton-Bold.ttf') format('truetype'),
    url('../assets/fonts/Campton/Campton-Bold.svg#Campton-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Campton';
  src: url('../assets/fonts/Campton/Campton-Medium.woff2') format('woff2'),
    url('../assets/fonts/Campton/Campton-Medium.woff') format('woff'),
    url('../assets/fonts/Campton/Campton-Medium.ttf') format('truetype'),
    url('../assets/fonts/Campton/Campton-Medium.svg#Campton-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Campton-Book';
  src: url('../assets/fonts/Campton/Campton-Book.woff2') format('woff2'),
    url('../assets/fonts/Campton/Campton-Book.woff') format('woff'),
    url('../assets/fonts/Campton/Campton-Book.ttf') format('truetype'),
    url('../assets/fonts/Campton/Campton-Book.svg#Campton-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}